<template>
	<div id="HomeCenter">
		<!-- 轮播宣传图 -->
		<div class="center-ad" @mouseover="cancelImgTimer" @mouseout="createImgTimer">
			<div class="ad-item">
				<!-- :src="imgList[curImgIndex].name" -->
				<img :src="imgList[curImgIndex].name" :alt="imgList[curImgIndex].title" />
			</div>
			<div class="ad-page" v-if="imgList.length > 1">
				<ul>
					<li @click="gotoPage(prevIndex)">&lt;</li>
					<li class="normal-page" :class="{ 'current-page': curImgIndex == index }"
						v-for="(item, index) in imgList" @click="gotoPage(index)" :key="index">
						{{ index + 1 }}
					</li>
					<li @click="gotoPage(nextIndex)">&gt;</li>
				</ul>
			</div>
		</div>
		<!-- 搜索产品 border-bottom: 2px solid #163E87;-->
		<div class="center-search">

			<div class="center-search-left"
				style="width: 30%; margin-left: 15px; line-height: 60px; font-size: 20px; font-weight: bold; text-align: left;">
				{{ $t("search_center.title") }}
			</div>

			<div class="center-search-right">
				<div class="product-name"
					style="width: 250px; display: flex; flex-direction: row; justify-content: center; align-items: center;">
					<span style="width: 80px;">
						{{ $t("search_center.name") }}:
					</span>
					<el-input style="border: none;" v-model="searchContent.productName"
						:placeholder="$t('search_center.input_name')">
					</el-input>
				</div>
				<div class="product-name"
					style="width: 250px; display: flex; flex-direction: row; justify-content: center; align-items: center;">
					<span style="width: 80px;">
						{{ $t("search_center.model") }}:
					</span>
					<el-input v-model="searchContent.model" :placeholder="$t('search_center.input_model')"></el-input>
				</div>
				<div class="product-name"
					style="width: 250px; display: flex; flex-direction: row; justify-content: center; align-items: center;">
					<span style="width: 80px;">
						{{ $t("search_center.use") }}:
					</span>
					<!-- <el-input v-model="searchContent.use"></el-input> -->
					<el-select v-model="selectedTagTempName" :placeholder="($t('search_center.select_tag'))"
						@change="selecteTagsTemplate" clearable>
						<el-option v-for="item in tagsTemplates" :key="item.id"
							:label="(cur_lang == 'zh' ? item.nameCh : item.nameEn)" :value="item.id">
						</el-option>
					</el-select>
				</div>


				<div class="search-btn"
					style="margin-left: 5px; margin-right: 5px; width: 80px; line-height: 40px; border-radius: 3px; background-color: #163E87; color: #FFF;  cursor: pointer;"
					@click="gotoSearch">{{ $t("search") }}
				</div>
			</div>


		</div>
		<!-- 新产品 -->
		<div class="center-product">
			<span v-if="(cur_lang == 'zh')" style="border-bottom: 4px solid #163E87; font-size: 25px;">新产品</span>
			<span v-else style="border-bottom: 4px solid #163E87; color: #163E87; font-size: 25px;">New Product</span>
			<div class="product-area">
				<div class="product" @click="viewProductDetail(item)" v-for="item in productList" :key="item.id">
					<el-image style="width: 250px; height: 250px; background-color: #FFF; border: none;" lazy
						:src="imageBaseURL + item.url + '?reduce=true'" :key='item.url' alt="新产品"></el-image>
					<img style="width: 40px; z-index: 1; position: absolute; top: 0px; left: 0px;"
						src="../assets/newest_icon.png" alt="新产品标签" />

					<!--  border: 1px solid #CCC; -->
					<div class="new-product-name"
						style="height: 30px; line-height: 30px; width: 248px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
						{{ cur_lang == "zh" ? item.nameCh : item.nameEn }}
					</div>
				</div>
			</div>
			<div class="lookmore-btn" @click="lookmore(1)">{{ $t("more_info") }}</div>
		</div>

		<!-- 解决方案 -->
		<div class="center-solution">
			<span v-if="(cur_lang == 'zh')" style="border-bottom: 4px solid #163E87; font-size: 25px;">解决方案</span>
			<span v-else style="border-bottom: 4px solid #163E87; color: #163E87; font-size: 25px;">Solution</span>
			<div class="solution-area">
				<div class="solution" @click="pagesolutionDetail(index)" @mouseover="showDescr(item)"
					@mouseleave="hideDescr(item)" v-for="(item, index) in solutionList" :key="item.id">
					<el-image style="width: 350px; height: 230px; background-color: #EEEEEE;" lazy
						:src="imageBaseURL + item.url + '?reduce=true'" :key='item.url' :alt="item.nameCh">
					</el-image>
					<div style="height: 30px; line-height: 30px; width: 348px; border: 1px solid #CCC;">
						{{ cur_lang == "zh" ? item.nameCh : item.nameEn }}
					</div>
					<div class="solution-descr"
						style="background-color: rgba(35, 85, 145, 0.8); width: 350px; height: 100%; z-index: 1; position: absolute; display: flex; flex-direction: column; justify-content: center; align-items: center; color: white;"
						v-if="item.status">
						<span style="font-size: 20px; font-weight: bold;">{{
              cur_lang == "zh" ? item.nameCh : item.nameEn
            }}</span>
						<span v-if="(cur_lang == 'zh')">{{ item.synopsis }}</span>
					</div>
				</div>
			</div>
			<div class="lookmore-btn" @click="lookmore(2)">{{ $t("more_info") }}</div>
		</div>

		<!-- 合作伙伴 -->
		<div class="center-partner">
			<span v-if="(cur_lang == 'zh')" style="border-bottom: 4px solid #163E87; font-size: 25px;">合作伙伴</span>
			<span v-else style="border-bottom: 4px solid #163E87; color: #163E87; font-size: 25px;">Partners</span>
			<div class="partner-area">
				<div class="partner" v-for="item in partnersList" :key="item.id">
					<el-image style="width: 200px;" lazy :src="imageBaseURL + item.url + '?reduce=true'" :key='item.url'
						alt="合作伙伴"></el-image>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "HomeCenter",
		data() {
			return {
				// 轮播图
				imgList: [{
					name: null,
					title: '传递,感知天下'
				}, {
					name: null,
					title: '定制特殊尺寸, 提供解决方案'
				}, {
					name: null,
					title: '一站式测力类传感器研产销企业'
				}],
				cur_lang: "zh",
				curImgIndex: 0,
				imgTimer: null,

				searchContent: {
					productName: '',
					model: '',
					tagName: '',
					tagId: '',
				},

				productList: [],
				solutionList: [],
				partnersList: [],

				tagsTemplates: [],
				selectedTagTempName: '',
			};
		},

		computed: {
			prevIndex() {
				if (this.curImgIndex == 0) {
					return this.imgList.length - 1;
				} else {
					return this.curImgIndex - 1;
				}
			},

			nextIndex() {
				if (this.curImgIndex == this.imgList.length - 1) {
					return 0;
				} else {
					return this.curImgIndex + 1;
				}
			}
		},

		created() {
			let cur_lang = localStorage.getItem("locale");
			if (cur_lang) {
				this.cur_lang = cur_lang
			}

			this.switchImage();

			this.createImgTimer();
			
			// 首页不使用缓存？
			this.getMainData()
			this.getProductTags()
			
		},

		mounted() {
			const that = this;
			// this.$vueUtils.lazyLoadImage();

			//监听缓存中指定key的值变化
			window.addEventListener("setItem", function(e) {
				// console.log('首页监听到了:', e.key);
				if (e.key == 'locale') {
					that.cur_lang = e.newValue;
					that.switchImage();
				}
			});

			// window.addEventListener("scroll", function(e) {
			// 	console.log('首页监听到了:', e);

			// 	that.$vueUtils.lazyLoadImage.bind(that.$vueUtils);
			// });
		},

		methods: {

			switchImage: function() {
				let adImage_01 = this.cur_lang == 'zh' ? require("@/assets/ad_images/transfer_img.png") : require(
					"@/assets/ad_images/transfer_img_en.png");
				let adImage_02 = this.cur_lang == 'zh' ? require("@/assets/ad_images/custom_size_img.png") :
					require("@/assets/ad_images/custom_size_img_en.png");
				let adImage_03 = this.cur_lang == 'zh' ? require("@/assets/ad_images/solution_img.png") : require(
					"@/assets/ad_images/solution_img_en.png");

				this.imgList[0].name = adImage_01;
				this.imgList[1].name = adImage_02;
				this.imgList[2].name = adImage_03;
			},
			
			getMainData: function(){
				let url = 'api/main/dataList';
				let that = this;
				this.$utils.httpRequestGet(url, null, function success(res) {
					
					let temList = res.data.productList;
					// 用于查看更多
					sessionStorage.setItem('product_list', JSON.stringify(temList))
					
					if (temList.length >= 4) {
						// 取3个
						for (var i = 0; i < 4; i++) {
							let solution = temList[i];
							that.productList.push(solution);
						}
					} else {
						that.productList = res.data.productList;
					}
					
					let list = res.data.schemeList;
					// 用于查看更多
					sessionStorage.setItem('scheme_tag_list', JSON.stringify(list))
					
					if (list.length >= 6) {
						// 取6个
						for (var i = 0; i < 6; i++) {
							let solution = list[i];
							that.solutionList.push(solution);
						}
					} else if (list.length >= 3) {
						// 取3个
						for (let j = 0; j < 3; i++) {
							let solution = list[j];
							that.solutionList.push(solution);
						}
					} else {
						that.solutionList = res.data.schemeList;
					}
					
					that.partnersList = res.data.partnerList;
					
					sessionStorage.setItem('partners_list', JSON.stringify(that.partnersList))
				}, function fail(res) {
					that.$alert(res.msg, '提示', {
						confirmButtonText: '确定',
						callback: action => {
							console.log('确定')
						}
					});
				});
			},

			getProductTags: function() {
				let url = 'api/main/getTag';
				let that = this;
				this.$utils.httpRequestGet(url, null, function success(res) {
					that.tagsTemplates = res.datas;
					sessionStorage.setItem('tag_templates', JSON.stringify(that.tagsTemplates))
					// console.log(res.data);

				}, function fail(res) {
					that.$alert(res.errmsg, '提示', {
						confirmButtonText: '确定',
						callback: action => {
							console.log('确定')
						}
					});
				});
			},
			

			selecteTagsTemplate: function(res) {
				// console.log('selecteTagsTemplate: ', typeof(res));
				if (typeof(res) != 'string') {
					// 查找标签
					let tagObj = this.tagsTemplates.find(function(curObj, index) {
						return curObj.id == res;
					})
					this.searchContent.tagName = this.cur_lang == 'zh' ? tagObj.nameCh : tagObj.nameEn;
					this.searchContent.tagId = res;
					// console.log('res: ', res, tagObj, this.searchContent.tag);
				}
			},

			cancelImgTimer: function() {
				// console.log('进入, 移除')
				clearInterval(this.imgTimer);
			},

			createImgTimer: function() {
				// console.log('离开,创建')
				this.imgTimer = setInterval(() => {
					this.gotoPage(this.nextIndex);
				}, 10000);
			},

			gotoPage: function(index) {
				this.curImgIndex = index;
			},

			showDescr: function(item) {
				// console.log("显示描述")
				item.status = 1;
				
				
			},

			hideDescr: function(item) {
				// console.log("隐藏描述")
				item.status = 0;
			},

			viewProductDetail: function(item) {
				// 点击记录
				let url = 'api/record/save';
				let that = this;
				let data = {
					productTwoId: item.id
				}
				this.$utils.httpRequestPost(url, data, function success(res) {
					console.log('添加浏览记录成功')
				}, function fail(res) {
					console.log('添加浏览记录失败')
				});
				

				let types = JSON.parse(sessionStorage.getItem('all_product_type_list'))
				
				// console.log(item, types);
				
				// 寻找对应的下标
				let index = -1;
				let sub_index = -1;
				for (let i = 0; i < types.length; i++) {
					let temp_type = types[i];
					for (let j = 0; j < temp_type.child.length; j++) {
						let temp_sub_type = temp_type.child[j];
						if (item.oneId == temp_sub_type.oneId
							&& item.id == temp_sub_type.id) {
							index = i;
							sub_index = j;
							break;
						}
					}
					if (index != -1) {
						// 已找到
						break;
					}
				}
				// console.log(index, sub_index)
				
				window.location.href = 'product.html?type_index=' + index + '&type_sub_index=' + sub_index
			},

			pagesolutionDetail: function(index) {
				// sessionStorage.setItem("solution_index", index);
				// sessionStorage.setItem("solution_sub_index", 0);
				window.location.href = 'solutiondetail.html?solution_index=' + index + '&solution_sub_index=0'
			},

			lookmore: function(index) {
				if (index == 1) {
					// 更多新产品
					window.location.href = 'newproduct.html'
				} else {
					// 更多方案
					window.location.href = 'solution.html'
				}
			},

			gotoSearch: function() {
				// console.log('search_content: ', this.searchContent)
				sessionStorage.setItem("search_content", JSON.stringify(this.searchContent));
				window.location.href = 'searchcenter.html'

			},


		}
	};
</script>

<style>
	#HomeCenter {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
	}

	.center-ad {
		width: 100%;
		background-color: #d2deee;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		position: relative;
	}

	.center-search {
		width: 1200px;
		height: 60px;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
		border-radius: 5px;
		margin-top: 10px;
		background-color: rgba(240, 240, 240, 0.7);
	}

	.center-search-right {
		width: 80%;
		height: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
	}

	.center-product,
	.center-solution,
	.center-partner {
		width: 1200px;
		margin-top: 30px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.center-product .product-area {
		margin-top: 35px;
		margin-bottom: 15px;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
	}

	.product-area .product {
		width: 250px;
		/* height: 280px; */
		cursor: pointer;
		border-radius: 5px;
		box-shadow: 0px 0px 5px 5px #EEE;
		margin-bottom: 20px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		/* 有两张图,需上下显示 */
		position: relative;

		background-color: #FFF;
	}

	.product-area .product:hover {
		transition: all 0.25s;
		transform: scale(1.05);
	}

	.product-area .product:hover .new-product-name {
		color: #fff;
		background-color: #163e87;
		border-bottom-right-radius: 5px;
		border-bottom-left-radius: 5px;
	}


	.center-solution .solution-area {
		margin-top: 15px;
		margin-bottom: 25px;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
	}

	.center-solution .solution {
		cursor: pointer;
		/* width: 350px;
		background-color: blueviolet; */

		position: relative;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-bottom: 20px;
	}

	.center-partner .partner-area {
		margin-top: 15px;
		margin-bottom: 40px;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
	}

	.partner-area .partner {
		margin-top: 10px;
		margin-right: 50px;
		width: 200px;
		height: 70px;
	}
	
	.partner:nth-child(5n+5){
		margin-right: 0px;
	}

	.lookmore-btn {
		width: 200px;
		height: 50px;
		line-height: 50px;
		background-color: rgba(240, 240, 240, 0.7);
		;
		color: #2c3e50;
		font-size: 20px;
		border-radius: 5px;
		cursor: pointer;
	}

	.center-ad .ad-item {
		width: 100%;
	}

	/* 轮播图 */
	.center-ad .ad-item img {
		width: 100%;
		min-width: 1200px;
		display: block;
	}

	.center-ad .ad-page {
		position: absolute;
		right: 0;
		bottom: 0;
		width: 100%;
		/* background: rgba(220, 225, 230, .1); */
	}

	.center-ad .ad-page ul {
		float: right;
	}

	.ad-page ul li {
		list-style: none;
		float: left;
		width: 25px;
		height: 20px;
		line-height: 20px;
		text-align: center;
		cursor: pointer;
		font-size: 14px;
		color: rgba(255, 255, 255, 0.8);
	}

	.normal-page {
		background-color: rgba(100, 100, 100, 0.2);
	}

	.current-page {
		background-color: #163e87;
	}
</style>

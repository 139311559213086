
import Vue from "vue";
import HomeView from "./HomeView.vue";

import '../../assets/css/common.css'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import i18n from '../../assets/i18n/index.js';

import Axios from 'axios';
Vue.prototype.$axios = Axios;

import * as utils from '../../utils/utils.js';
Vue.prototype.$utils = utils;

// import vueLazyLoad from 'vue-lazyload';
// Vue.use(vueLazyLoad);

Vue.use(ElementUI);

Vue.config.productionTip = false;

// console.log('当前域名:', process.env);

// 默认url
Axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
// 是否带上身份验证，支持发送cookie凭证信息，如果设置了true, 后端就不能设置Access-Control-Allow-Origin 为 *
Axios.defaults.withCredentials = false;
Vue.prototype.$axios = Axios;


// 用于图片process.env.VUE_APP_BASE_URL
Vue.prototype.imageBaseURL = process.env.VUE_APP_BASE_URL;

/**
 * @description
 * @author (Set the text for this tag by adding docthis.authorName to your settings file.)
 * @date 2019-05-29
 * @param { number } type 1 localStorage 2 sessionStorage
 * @param { string } key 键
 * @param { string } data 要存储的数据
 * @returns 
 */
Vue.prototype.$addStorageEvent = function(type, key, data) {
	if (type === 1) {
		// 创建一个StorageEvent事件
		let storageEvent = document.createEvent('StorageEvent');
		const storage = {
			setItem: function(k, val) {
				localStorage.setItem(k, val);
				// 初始化创建的事件
				storageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
				// 派发对象
				window.dispatchEvent(storageEvent);
			}
		}
		return storage.setItem(key, data);
	} else {
		// 创建一个StorageEvent事件
		let storageEvent = document.createEvent('StorageEvent');
		const storage = {
			setItem: function(k, val) {
				sessionStorage.setItem(k, val);
				// 初始化创建的事件
				storageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
				// 派发对象
				window.dispatchEvent(storageEvent);
			}
		}
		return storage.setItem(key, data);
	}
}

new Vue({
	i18n,
	render: h => h(HomeView),
}).$mount("#index");
